body {
    position: relative;
    color: #D4D4D4 !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 1.8 !important;
    background: #000 !important;
    font-family: 'Roboto', sans-serif !important;
    overflow-x: hidden;
    z-index: 1;
}
body:before{
    width: 100%;
    height: 100%;
    content: " ";
    background-image: url(../images/bodybg-1.png);
    position: absolute;
    left: -16px;
    top: -6px;
    z-index: -1;
    opacity: 0.5;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif !important;
}
h2 {
    font-weight: 700 !important;
    font-size: 42px !important;
    padding-bottom: 18px;
    background: -webkit-linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%, rgb(44, 47, 53) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
h2 span {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(34,159,212,1) 52%, rgba(89,49,234,1) 100%);
}
h4 {
    font-size: 22px !important;
    padding-bottom: 10px;
}
h6 {
    color: #8222D4 !important;
    font-size: 18px !important;
    letter-spacing: 8px;
    text-transform: uppercase;
    font-weight: 600 !important;
    padding-bottom: 10px;
}
.primaryBtn, .primaryBtn:focus {
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
    transition: all .6s ease 0s;
}
.primaryBtn:hover {
    color: #fff;
    background: linear-gradient(105deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
}
.secondaryBtn, .secondaryBtn:focus {
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #8222d4;
    background: #2b2b2b;
    transition: all .6s ease 0s;
}
.secondaryBtn:hover {
    color: #fff;
    border: 1px solid #8222d4;
    background: #8222d4;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
}
.buttonSec {
    padding: 30px 0;
}
/* Navbar Start */
.nav {
    z-index: 100;
}
.nav-link { 
    color: #D4D4D4 !important;
}
.fixed-top {
    z-index: 100;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #fff !important;
}
.navbar-brand img {
    width: 175px;
}
.navBtn, .navBtn:focus {
    color: #fff !important;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
    transition: all .6s ease 0s;
}
.navBtn:hover {
    color: #fff;
    background: linear-gradient(105deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
}
.dropdown-menu {
    background: #0d0d0d !important;
    border: 1px solid #5f2fe77d !important;
}
.dropdown-menu a, .dropdown-menu a:focus {
    color: #D4D4D4 !important;
}
.dropdown-menu a:hover {
    color: #8222D4 !important;
    background: none;
}
.dropdown-divider {
    border-top: 1px solid rgb(45 43 47 / 60%) !important;
}
.expertSec {
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    position: fixed;
    top: 35%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 145px;
    height: 55px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
    box-shadow: 0 20px 50px rgba(0,0,0,.5);
    box-sizing: border-box;
    transition: 0.5s;
    right: -68px;
    text-align: center;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 9999999;
}
/* Navbar End */
/* Home Start */
.bannerSec {
    padding: 15.2% 0 12% 0;
}
.bannerSec h1 {
    font-weight: 700;
    font-size: 72px;
    background: -webkit-linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%, rgb(44, 47, 53) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.bannerSec h1 span {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(34,159,212,1) 52%, rgba(89,49,234,1) 100%);
}
.bannerSec h1 img {
    max-width: 7%;
    width: 100px;
    margin-left: 2%;
}
.bannerSec p {
    color: #D4D4D4;
    max-width: 55%;
    padding: 2% 0;
}
/* Home End */
/* Counter Start */
.counterSec h4 {
    color: #fff;
    font-size: 42px !important;
    font-weight: 600;
    position: relative;
    padding-left: 10px;
}
.counterSec h4::before {
    position: absolute;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(34,159,212,1) 52%, rgba(89,49,234,1) 100%);
    width: 3px;
    content: '';
    top: 5px;
    z-index: 1;
    left: 0;
    height: 32px;
    bottom: 0
}
.counterSec p {
    font-weight: 400;
    padding-top: 5px;
}
/* Counter End */
/* About Start */
.aboutSec {
    padding: 95px 0 75px 0;
    margin-top: 120px;
    position: relative;
}
.aboutSec::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    z-index: -1;
    transform: rotate(358deg);
    background: rgb(17 17 17 / 75%);
}
/* About End */
/* Home Services Start */
.home-servicesSec {
    padding-top: 140px;
}
.home-servicesSec .nav-tabs {
    border-bottom: 2px solid #1a103f;
}
.home-servicesSec .nav-tabs .nav-link, .home-servicesSec .nav-tabs .nav-link:focus {
    color: #939495 !important;
    font-size: 18px;
    font-weight: 500;
    margin-right: 35px;
    border: none;
    background: none !important;
}
.home-servicesSec .nav-tabs .nav-item.show .nav-link, .home-servicesSec .nav-tabs .nav-link.active, .home-servicesSec .nav-tabs .nav-link:hover {
    border-bottom: 3px solid #5931EA !important;
    color: #fff !important;
}
.home-servicesSec .tab-pane {
    padding: 40px 0;    
}
/* Home Services End */
/* Home Tech Start */
.home-techSec {
    padding-top: 100px;
}
.home-techSec h2 {
    padding-bottom: 3%;
}
.home-techSec-box {
    background: #0D0D0D;
    border: 1px solid #611C9E;
    border-radius: 20px;
    text-align: center;
    margin: 15px 0;
    padding: 25px 15px;
}
.home-techSec-box img {
    width: 70px;
    padding-bottom: 10px;
}
.home-techSec-box h5 {
    font-size: 18px;
}
/* Home Tech End */
/* Home Product Start */
.home-productSec {
    padding: 95px 0 75px 0;
    margin-top: 120px;
    position: relative;
}
.home-productSec::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    z-index: -1;
    transform: rotate(358deg);
    background: rgb(17 17 17 / 75%);
}
.home-productSec .carousel-control-prev, .home-productSec .carousel-control-next {
    display: none;
}
.home-productSec .carousel-indicators {
    position: absolute;
    left: initial;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 5%;
    margin-left: 15%;
}
.home-productSec .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 6px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: content-box !important;
    background: linear-gradient(105deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
}
.home-productSec .carousel-indicators .active {
    opacity: 1;
    width: 25px !important;
}
/* Home Product End */
/* Why Choose Start */
.home-whychooseSec {
    padding-top: 140px;
}
.home-whychooseSec-box {
    background: rgb(17 17 17 / 75%);
    border: 1px solid #611C9E;
    border-radius: 20px;
    margin: 22px 0;
    padding: 30px;
    text-align: right;
}
.home-whychooseSec-box img {
    width: 80px;
    height: 80px;
    background: #000;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 15px;
}
.home-whychooseSec-box h4 {
    text-align: left;
    font-size: 22px;
    color: #fff;
}
.home-whychooseSec-box p {
    text-align: left;
    margin-bottom: 0;
}
.whychooseSec-box-two {
    transform: rotate(355deg);
    margin-top: 40px;
}
.whychooseSec-box-three {
    transform: rotate(1deg);
    margin-top: -10px;
}
/* Why Choose End */
/* Home Faq Start */
.home-faqSec {
    padding-top: 100px;
}
.home-faqSec .accordion-item {
    background: transparent;
    border: none;
}
.home-faqSec .accordion-item button {
    color: #fff !important;
    background: rgb(17 17 17 / 75%);
    border: 1px solid #611C9E;
    border-radius: 20px;
    font-size: 18px;
    padding: 20px 25px;
}
.home-faqSec .accordion-item button:focus {
    box-shadow: none;
    outline: 0;
}
.home-faqSec .accordion-item h2 {
    color: #fff !important;
    -webkit-text-fill-color: initial;
}
.home-faqSec .accordion-collapse {
    background: #151515;
    margin-bottom: 20px;
    margin-top: 0;
    border-radius: 12px;
    color: #fff;
}
.home-faqSec .accordion-button::after {
    display: none;
}
/* Home Faq End */
/* Get Know Start */
.getknowSec {
    padding: 95px 0 60px 0;
    margin-top: 120px;
    position: relative;
}
.getknowSec::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    z-index: -1;
    transform: rotate(358deg);
    background: rgb(17 17 17 / 75%);
}
/* Get Know End */
/* Form Start */
.footerForm {
    padding: 140px 0;
}
.footerForm button, .footerForm button:focus {
    border: none;
    padding: 8px 50px;
}
.footerForm .form-control {
    color: #fff !important;
    background: rgb(17 17 17 / 75%);
    border: 1px solid #611C9E;
    border-radius: 16px;
    font-size: 18px;
    padding: 12px 25px;
    margin-bottom: 25px;
}
.footerForm .form-control::placeholder {
    color: #737982;
    opacity: 1; /* Firefox */
}
  
.footerForm .form-control::-ms-input-placeholder { /* Edge 12 -18 */
    color: #737982;
}
.footerForm .form-control:focus {
    color: #fff !important;
    background: rgb(39 39 39 / 55%) !important;
    border: 1px solid #611C9E !important;
    outline: 0;
    box-shadow: none !important;
}
/* Form End */
/* Footer Start */
footer {
    padding-top: 85px;
    position: relative;
    background: rgb(17 17 17 / 75%);
}
footer a, footer a:focus {
    display: block;
    color: #D4D4D4;
    text-decoration: none;
    transition: all .6s ease 0s;
}
footer a:hover {
    color: #8222D4;
    text-decoration: none;
}
footer h4 {
    font-size: 20px;
    padding-bottom: 10px;
}
footer p {
    margin-bottom: 0;
}
footer .socialIcon {
    display: flex;
}
footer .socialIcon img {
    width: 25px;
}
footer .socialIcon a {
    padding: 25px 20px 15px 0;
    transition: all .6s ease 0s;
}
footer .socialIcon a:hover {
    opacity: 0.6;
}
footer .content {
    margin-top: 20px;
}
footer .content input {
    height: 46px;
    padding-left: 20px;
    color: #fff !important;
    border-radius: 10px;
    border: 1px solid #611C9E !important;
    background: rgb(39 39 39 / 55%) !important;
}
footer .content input:focus {
    outline: 0;
    box-shadow: none;
}
footer .content input::placeholder {
    color: #737982;
    opacity: 1; /* Firefox */
}
  
footer .content input::-ms-input-placeholder { /* Edge 12 -18 */
    color: #737982;
}
footer .content button, footer .content button:focus {
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 0 10px 10px 0;
    border: none;
    font-size: 14px;
    font-weight: 400;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
    transition: all .6s ease 0s;
}
footer .content button:hover {
    color: #fff;
    background: linear-gradient(105deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(89,49,234,1) 100%);
}
.copyRight {
    border-top: 1px solid rgb(158 158 158 / 24%);
    padding: 15px 0;
    margin-top: 65px;
    font-size: 14px;
}
.copyRight-text a {
    display: inline-block;
}
/* Footer End */
/* Nft Start */
.pageBanner {
    padding: 12% 0 7% 0;
}
.pageBanner h1 {
    font-weight: 700;
    font-size: 72px;
    padding-bottom: 4%;
    background: -webkit-linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%, rgb(44, 47, 53) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.pageBanner h1 span {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(130,34,212,1) 0%, rgba(34,159,212,1) 52%, rgba(89,49,234,1) 100%);
}
.nftadvantageSec {
    padding: 95px 0 75px 0;
    margin-top: 120px;
    position: relative;
}
.nftadvantageSec::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    z-index: -1;
    transform: rotate(358deg);
    background: rgb(17 17 17 / 75%);
}
.nftadvantageSec p {
    background: #000;
    border-radius: 20px;
    padding: 25px 30px;
    margin-bottom: 25px;
}
.nftadvantageSec .leftBorder {
    border-left: 2px solid #5b30e8;
}
.nftadvantageSec .rightBorder {
    border-right: 2px solid #5b30e8;
}
.industriesSec {
    padding-top: 150px;
}
.industriesSec-box {
    background: #0d0d0d;
    border-radius: 20px;
    padding: 25px 30px;
    margin-bottom: 30px;
    height: 245px;
    min-height: auto;
}
.dflex {
    display: flex;
    align-items: center;
}
.industriesSec-box img {
    width: 65px;
    height: 65px;
    background: #000;
    padding: 10px;
    margin-bottom: 15px;
    margin-right: 15px;
    border-radius: 10px;
}
.nftmarketplaceSec {
    padding-top: 100px;
}
.nftchainSec {
    padding-top: 120px;
}
.nftchainSec h2 {
    padding-bottom: 35px;
}
.nftchainSec-box {
    display: flex;
}
.nftchainSec-box {
    display: flex;    
    flex-wrap: wrap;
}
.nftchainSec-box-label {
    background: #0d0d0d;
    border-radius: 20px;
    padding: 15px 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-right: 2px solid #5b30e8;
}
.nftfeatureSec {
    padding: 95px 0 75px 0;
    margin-top: 120px;
    position: relative;
}
.nftfeatureSec::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    z-index: -1;
    transform: rotate(358deg);
    background: rgb(17 17 17 / 75%);
}
.nftfeatureSec h2 {
    padding-bottom: 35px;
}
.nftfeatureSec-box {
    background: #000;
    border-radius: 20px;
    padding: 25px 30px;
    margin-bottom: 25px;
    /* border-bottom: 2px solid #5b30e8; */
    position: relative;
    min-height: 200px;
    height: auto;
}
.nftfeatureSec-box img {
    width: 100px;
    height: 100px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: end;
    opacity: .1;
}
.nftmultiSec {
    padding-top: 140px;
}
.nftmultiSec-box {
    color: #fff !important;
    background: rgb(17 17 17 / 75%);
    border: 1px solid #611C9E;
    border-radius: 20px;
    margin: 12px 0;
}
.nftmultiSec-box h4 {
    border-radius: 20px 20px 0px 0px;
    background: #5931ea;
    padding: 18px 30px;
    font-size: 20px !important;
}
.nftmultiSec-box ul {
    padding-left: 0;
}
.nftmultiSec-box li {
    border-bottom: 1px solid #611C9E;
    padding: 10px 30px;
    list-style: none;
}
.nftmultiSec-box li:last-child {
    border-bottom: none;
    margin-bottom: -15px;
}
.nftwhySec {
    padding-top: 130px;
}
.nftwhySec-box {
    background: #0d0d0d;
    border-radius: 20px;
    padding: 25px 30px;
    margin-bottom: 30px;
    height: 180px;
    min-height: auto;
    position: relative;
}
.nftwhySec-box span {
    position: absolute;
    right: 15px;
    bottom: -15px;
    font-size: 60px;
    font-weight: 600;
    z-index: 0;
    color: #5b30e8;
    opacity: 0.3;
}
/* Nft End */
/* Exchange Start */
.exchangecentralizedSec {
    padding-top: 120px;
}
.exchangep2pSec {
    padding-top: 120px;
}
.exchangesolSec {
    padding-top: 120px;
}
.exchangepeerSec {
    padding-top: 120px;
}
.exchangehybSec {
    padding-top: 120px;
}
.exchangetechSec {
    padding-top: 120px;
}
.exchangetechSec-box {
    background: #0d0d0d;
    border-radius: 20px;
    padding: 25px 30px;
    margin: 18px 0;
    height: 315px;
    min-height: auto;
}
.exchangetechSec-box-title {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}
.exchangetechSec-box-title h4 {
    padding-top: 10px;
    padding-left: 10px;
}
.exchangetechSec-box img {
    width: 50px;
    height: 50px;
    padding-right: 10px;
}
.exchangecentSec {
    padding-top: 100px;
}
.exchangecentSec-box {
    display: flex;
    flex-wrap: wrap;
}
.exchangecentSec-box-label {
    background: #0d0d0d;
    border-radius: 20px;
    padding: 15px 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-right: 2px solid #5b30e8;
}


.extimelineSec {
    padding-top: 120px;
  }
  .extimelineSec h2 {
    padding-bottom: 50px;
  }
  .timelineSec h4 {
    margin-bottom: 0;
    background: #5b30e8;
    border-radius: 18px 0px 0px 18px;
    padding: 16px;
    font-size: 18px !important;
  }
  .timelineSec p {
    margin-bottom: 0;
    color: #D4D4D4 !important;
    font-size: 18px;
    font-weight: 400;
    padding: 12px 25px;
  }
  .timelineSec ul {
    padding-left: 0;
  }
  .timelineSec ul li {
    background: rgb(91 48 232 / 35%);
    position: relative;
    margin: 0 auto;
    width: 5px;
    padding-bottom: 40px;
    list-style-type: none;
  }
  .timelineSec ul li:last-child {
    padding-bottom: 7px;
  }
  .timelineSec ul li:before {
    content: "";
    background: #360e57;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border: 3px solid #8222D4;
    border-radius: 50%;
  }
  .timelineSec ul li .hidden {
    opacity: 0;
  }
  .timelineSec ul li .content {
    background: #0d0d0d;
    position: relative;
    top: 7px;
    width: 450px;
  }
  .timelineSec ul li .content:before {
    content: "";
    background: rgb(91 48 232 / 35%);
    position: absolute;
    top: 0px;
    width: 38px;
    height: 5px;
  }
  .timelineSec ul li:nth-child(odd) .content {
    border-radius: 20px;
    display: flex;
    left: 50px;
    background: #0d0d0d;
    border: 1px solid #611C9E;
  }
  .timelineSec ul li:nth-child(odd) .content:before {
    left: -38px;
  }
  .timelineSec ul li:nth-child(even) .content {
    border-radius: 20px;
    display: flex;
    left: calc(-450px - 45px);
    background: #0d0d0d;
    border: 1px solid #611C9E;
  }
  .timelineSec ul li:nth-child(even) .content:before {
    right: -38px;
  }
  @media screen and (max-width: 1020px) {
    .timelineSec ul li .content {
      width: 41vw;
    }
  
    .timelineSec ul li:nth-child(even) .content {
      left: calc(-41vw - 45px);
    }
  }
  @media screen and (max-width: 700px) {
    .timelineSec .content h4 span {
        display: none;
    }
    .timelineSec p {
        font-size: 15px;
        padding: 12px 15px;
    }
    .timelineSec ul li {
      margin-left: 20px;
    }
    .timelineSec ul li .content {
      width: calc(100vw - 100px);
    }
    .timelineSec ul li .content h2 {
      text-align: initial;
    }
    .timelineSec ul li:nth-child(even) .content {
      left: 45px;
      background: rgb(91 48 232 / 100%);
    }
    .timelineSec ul li:nth-child(even) .content:before {
      left: -33px;
    }
  }
  .exchooseSec {
    padding-top: 120px;
  }
/* Exchange End */
/* Devlopment Start */
.devtokenSec-box {
    background: #0d0d0d;
    border-radius: 20px;
    padding: 25px 30px;
    margin: 15px 0;
    height: 88%;
}
.devstepSec {
    padding: 95px 0 75px 0;
    margin-top: 120px;
    position: relative;
}
.devstepSec::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    z-index: -1;
    transform: rotate(358deg);
    background: rgb(17 17 17 / 75%);
}
.devstepSec h3 {
    font-weight: 700 !important;
    font-size: 36px !important;
    padding-bottom: 18px;
    background: -webkit-linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%, rgb(44, 47, 53) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 40px;
}
.devstepSec-box {
    background: #000;
    border-radius: 20px;
    padding: 25px 30px;
    margin-bottom: 25px;
    height: 88%;
    position: relative;
}
.devstepSec-box span {
    position: absolute;
    right: 20px;
    bottom: 0;
    font-size: 44px;
    font-weight: 600;
    opacity: 0.6;
    color: #8222d4;
}
.devstepSec .leftBorder {
    border-left: 2px solid #5b30e8;
}
.devstepSec .rightBorder {
    border-right: 2px solid #5b30e8;
}
.devwhySec {
    padding-top: 140px;
}
.devwhySec-box {
    background: rgb(17 17 17 / 75%);
    border: 1px solid #611C9E;
    border-radius: 16px;
    font-size: 18px;
    padding: 20px 25px 0px;
    margin: 15px 0;
    height: 88%;
}
/* Development End */
@media screen and (max-width: 798px) {
    h1 {
        font-size: 44px !important;
    }
    h2 {
        font-size: 32px !important;
    }
    h6 {
        font-size: 16px !important;
    }
    .aboutSec::before, .getknowSec::before, .home-productSec::before, .nftfeatureSec::before, .nftadvantageSec::before {
        width: 100%;
        left: 0;
        transform: initial;
    }
    .navbar-brand img {
        width: 135px;
    }
    .navbar-toggler {
        background: #8222D4 !important;
    }
    .fixed-top {
        z-index: 1000000 !important;
        background: #111111 !important;
        height: auto !important;
        padding-bottom: 20px;
    }
    .navBtn, .navBtn:focus {
        width: 110px;
        margin-top: 20px;
    }
    .bannerSec {
        padding: 22% 0 15% 0;
    }
    .bannerSec p {
        color: #D4D4D4;
        max-width: 100%;
        padding: 4% 0;
    }
    .home-servicesSec .nav-tabs .nav-link, .home-servicesSec .nav-tabs .nav-link:focus {
        margin-right: 20px;
    }
    footer h4 {
        font-size: 20px;
        padding-bottom: 5px;
        padding-top: 30px;
    }
    .industriesSec-box {
        height: 88%;
    }
    .nftwhySec-box {
        height: 88%;
    }
    .exchangetechSec-box {
        height: 88%;
    }
}
@media screen and (min-width: 1028px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 1000px)  {
    .aboutSec::before, .getknowSec::before, .home-productSec::before, .nftfeatureSec::before, .nftadvantageSec::before {
        width: 100%;
        left: 0;
        transform: initial;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}